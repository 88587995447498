<template>
  <div class="home">
    <div class="container">
      <v-header :menu="true" />
    </div>
    <div class="section dt12 db15 mv7">
      <div class="container">
        <div class="row">
          <div class="d-6">
            <h1>О&nbsp;разработчике</h1>
            <p class="md">
              <a href="https://ediweb.com/" target="_blank"
                ><strong>Ediweb</strong></a
              >&nbsp;&mdash; разработчик цифровой b2b-экосистемы, оператор
              электронного документооборота и&nbsp;интегратор. Мы&nbsp;помогаем
              оцифровать и&nbsp;синхронизировать работу с&nbsp;данными
              на&nbsp;всех этапах цепочки поставок: от&nbsp;закупок
              и&nbsp;продаж до&nbsp;логистики и&nbsp;управления финансовыми
              потоками.
            </p>
            <p class="md">
              Решения Ediweb используют промышленные и&nbsp;транспортные
              компании, торговые сети, дистрибьюторы, финансовые организации,
              телеком и&nbsp;государственные органы.
            </p>
            <p class="md">
              Офисы расположены в&nbsp;Санкт-Петербурге и&nbsp;Смоленске.
            </p>
          </div>
          <div class="d-5 d-offset-1">
            <img
              src="@/assets/e-logo.png"
              class="m-hide"
              alt="Ediweb Logo"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section v15 mv7">
      <div class="container">
        <div class="row">
          <div class="d-12 db10 mb6 d-center" v-onscroll>
            <h2>Как мы&nbsp;работаем</h2>
          </div>
        </div>
        <div class="row" style="position: relative">
          <div class="d-4 d-center mb6" v-onscroll="{ delay: 250 }">
            <div class="dr8 dl8">
              <img src="@/assets/icons/doc-checked.svg" class="d-center" />
              <h4 class="dv3 mv3">Выбор и&nbsp;отправка запроса</h4>
              <p class="md">
                Вы&nbsp;выбираете подходящую услугу и&nbsp;отправляете запрос.
              </p>
            </div>
          </div>

          <v-dotted-line
            v-onscroll="{ delay: 250 }"
            style="top: 10%; left: 23%"
            svgWidth="268"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="268"
              height="52"
              fill="none"
            >
              <defs>
                <mask id="theMask">
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M1 1c86.981 50 210.878 50 266 50"
                  />
                </mask>
              </defs>
              <g mask="url(#theMask)">
                <path
                  stroke="#1E2128"
                  stroke-dasharray="5 15"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M1 1c86.981 50 210.878 50 266 50"
                />
              </g>
            </svg>
          </v-dotted-line>

          <div class="d-4 d-center mb6" v-onscroll="{ delay: 500 }">
            <div class="dr3 dl3 dt7">
              <img src="@/assets/icons/chat.svg" class="d-center" />
              <h4 class="dv3 mv3">Получение предложения</h4>
              <p class="md">
                Мы&nbsp;проводим предварительную оценку и&nbsp;отправляем вам
                индвидуальное предложение.
              </p>
            </div>
          </div>

          <v-dotted-line
            v-onscroll="{ delay: 500 }"
            style="top: 8%; right: 22%"
            animationDelay="1000"
            svgWidth="270"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="270"
              height="52"
              fill="none"
            >
              <defs>
                <mask id="theMask2">
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M1 51C34.0805 34.3333 133.993 1 269 1"
                  />
                </mask>
              </defs>
              <g mask="url(#theMask2)">
                <path
                  stroke="#1E2128"
                  stroke-dasharray="5 15"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M1 51C34.0805 34.3333 133.993 1 269 1"
                />
              </g>
            </svg>
          </v-dotted-line>

          <div class="d-4 d-center" v-onscroll="{ delay: 750 }">
            <div class="dr8 dl8">
              <img src="@/assets/icons/trust.svg" class="d-center" />
              <h4 class="dv3 mv3">Соглашение</h4>
              <p class="md">
                Далее вы&nbsp;заключаете соглашение и&nbsp;получаете
                индивидуальное решение ваших задач.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section db15 mb7" v-onscroll>
      <div class="container">
        <div class="row">
          <div class="d-8 d-offset-2 db9 mb6 d-center">
            <h2>Лицензии и&nbsp;сертификаты</h2>
            <p class="leading">
              Ediweb является доверенным оператором электронного
              документооборота и&nbsp;имеет все необходимые лицензии
              и&nbsp;сертификаты от&nbsp;контролирующих органов.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="d-12">
            <v-carousel
              :slidesPerPage="slidesPerPage"
              :slidesImages="slidesImages"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const meta = {
  title: "WorkCat − автоматизация кадрового документооборота и HR-процессов",
  description:
    "Управление подписанием и маршрутизацией кадровых документов. Помощь в запуске проектов по КЭДО",
};

export default {
  name: "About",
  components: {
    "v-header": () => import("@/components/v-header.vue"),
    "v-carousel": () => import("@/components/v-carousel.vue"),
    "v-dotted-line": () => import("@/components/v-dotted-line.vue"),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "description",
        content: meta.description,
      },
      {
        name: "og:description",
        content: meta.description,
      },
    ],
  },
  data() {
    return {
      slidesPerPage: [
        [320, 1],
        [570, 3],
        [876, 4],
      ],
      slidesImages: [
        [
          "/files/licenses/preview/01.jpg",
          "/files/licenses/preview/02.jpg",
          "/files/licenses/preview/03.jpg",
          "/files/licenses/preview/04.jpg",
        ],
        [
          "/files/licenses/original/01.jpg",
          "/files/licenses/original/02.jpg",
          "/files/licenses/original/03.jpg",
          "/files/licenses/original/04.jpg",
        ],
      ],
    };
  },
};
</script>
